export const ARTISTS_RECORD_KEY = 'artists';
export interface ARTIST {
    advance_img: string;
    agency_id?: string;
    archived: boolean;
    assigned_to: string[];
    createdAt: Date;
    logo: string;
    name: string;
    photo: string;
    uid: string;
    website: string;
}
