import { Routes } from '@angular/router';
import { AuthGuard } from '@common/guards';
import { AdminGuard } from '@common/guards/admin.guard';

export const ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/login',
    },
    {
        path: 'login',
        loadChildren: () => import('@tminc/feature-login').then((m) => m.FeatureLoginModule),
    },
    {
        path: 'advance',
        loadChildren: () => import('./views/advance/advance.module').then((m) => m.AdvanceModule),
    },
    {
        path: 'day-sheet',
        loadChildren: () => import('./views/day-sheet/day-sheet.module').then((m) => m.DaySheetModule),
    },
    {
        path: 'venue-day-sheet',
        loadChildren: () => import('./views/venue-day-sheet/venue-day-sheet.module').then((m) => m.VenueDaySheetModule),
    },
    {
        path: 'admin',
        loadChildren: () => import('./views/admin/admin.module').then((m) => m.AdminModule),
        canActivate: [AdminGuard],
    },
    {
        path: '',
        loadChildren: () => import('./views/shell/shell.module').then((m) => m.ShellModule),
        canActivate: [AuthGuard],
    },
    { path: '**', pathMatch: 'full', redirectTo: '/login' },
];
