import { FormlyFieldConfig } from '@ngx-formly/core';
import { formlyRow, formlyInput, formlySelect, formlyChipsAutoComplete } from '../formly-helpers';
import { ContactConfig, ContactsConfig, PersonnelType } from './contacts.interfaces';
import { getContactSelectOptions } from './contact-select-options';

export const PersonnelTourParty = 'personnel_tour_party';
export interface ContactType {
    name: string;
    type: string;
    title?: string;
    phone?: string;
    email?: string;
    contact?: string[];
}

export const Contact = (contactConfig: ContactConfig): FormlyFieldConfig[] => {
    return [
        formlyRow([
            formlyInput({
                key: 'name',
                templateOptions: {
                    required: true,
                    ...contactConfig.templateOptions,
                },
            }),
            formlySelect({
                key: 'type',
                templateOptions: {
                    required: true,
                    options: getContactSelectOptions(contactConfig.personnelType),
                    ...contactConfig.templateOptions,
                },
            }),
            formlyInput({
                key: 'title',
                templateOptions: {
                    ...contactConfig.templateOptions,
                },
            }),
        ]),
        formlyRow([
            formlyInput({
                key: 'phone',
            }),
            formlyInput({
                key: 'email',
                validators: {
                    validation: ['email'],
                },
            }),
        ]),
        formlyRow([
            formlySelect({
                key: 'contact',
                templateOptions: {
                    required: true,
                    options: getContactSelectOptions(PersonnelType.TOUR_CONACT),
                    label: 'Assign As Contact For...',
                    description: 'Assign This Contact To Specific Advance Page(s). Select All That Apply.',
                    selectAllOption: 'Select All',
                    multiple: true,
                    ...contactConfig.templateOptions,
                },
            }),
            formlyChipsAutoComplete({
                key: 'dietary_note',
                templateOptions: {
                    filter: ['None', 'Vegan', 'Vegetarian'],
                },
            }),
        ]),
    ];
};

export const FormlyContactsType = 'contacts';

export const Contacts = (config: ContactsConfig): FormlyFieldConfig[] => {
    return [
        {
            key: config.key,
            type: FormlyContactsType,
            templateOptions: {
                addText: config.addText,
            },
            fieldArray: {
                fieldGroup: [...Contact(config.contactConfig)],
            },
        },
    ];
};

export const VenueContact = (contactConfig: ContactConfig, className): FormlyFieldConfig[] => {
    return [
        formlyRow([
            formlyInput({
                key: 'name',
                templateOptions: {
                    ...contactConfig.templateOptions,
                    required: true,
                },
            }),
            formlySelect({
                className,
                key: 'type',
                templateOptions: {
                    options: getContactSelectOptions(contactConfig.personnelType),
                    ...contactConfig.templateOptions,
                    required: true,
                },
            }),
            formlyInput({
                className,
                key: 'title',
                templateOptions: {
                    ...contactConfig.templateOptions,
                },
            }),
        ]),
        formlyRow([
            formlyInput({
                className,
                key: 'phone',
            }),
            formlyInput({
                className,
                key: 'email',
                validators: {
                    validation: ['email'],
                },
            }),
        ]),
        formlySelect({
            key: 'contact',
            templateOptions: {
                required: true,
                options: getContactSelectOptions(contactConfig.personnelContact),
                label: 'Assign As Contact For...',
                description: 'Assign This Contact To Specific Advance Page(s). Select All That Apply.',
                multiple: true,
                selectAllOption: 'Select All',
                ...contactConfig.templateOptions,
            },
        }),
    ];
};
export const VenueContactFields = (config: ContactsConfig, className = ''): FormlyFieldConfig[] => {
    return [
        {
            key: config.key,
            type: FormlyContactsType,
            templateOptions: {
                addText: config.addText,
            },
            fieldArray: {
                fieldGroup: [...VenueContact(config.contactConfig, className)],
            },
        },
    ];
};
