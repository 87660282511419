import { FormlyFieldConfig } from '@ngx-formly/core';
import { formlyRow, TextArea, formlySelect } from '../formly-helpers';
import { BuyerListOptions, PrePostShowOptions, DistributedByOptions } from './meet-greet-options';
import { YES_NO_OPTIONS } from '@formly/common/select-options';

export const FormlyMeetGreetType = 'meet_greet';
export const MeetGreetSchedules = 'meet_greet_schedules';
export const VenueMeetGreetNotes = `${FormlyMeetGreetType}_venue_notes`;

export const MeetGreetFields = (config: { key: string }): FormlyFieldConfig[] => {
    return [
        TextArea('format', {}, config.key),
        formlyRow([
            formlySelect({
                key: `${config.key}_buyer_list`,
                templateOptions: {
                    label: 'M&G Buyer List',
                    options: BuyerListOptions,
                },
            }),
            formlySelect({
                key: `${config.key}_pre_post_show`,
                templateOptions: {
                    options: PrePostShowOptions,
                    label: 'Pre or Post Show',
                },
            }),
        ]),
        formlyRow([TextArea('ticket_capacity', {}, config.key), TextArea('photo_policy', {}, config.key)]),
        formlyRow([
            formlySelect({
                key: `${config.key}_vip_merch`,
                templateOptions: {
                    label: 'M&G VIP Merch',
                    options: YES_NO_OPTIONS,
                },
            }),
            formlySelect({
                key: `${config.key}_distributed_by`,
                templateOptions: {
                    options: DistributedByOptions,
                },
            }),
        ]),
    ];
};

export const VenueMeetGreetFields = (config: { key: string }): FormlyFieldConfig[] => {
    return [
        formlyRow([
            TextArea('tickets_sold', { label: '# M&G Tickets Sold' }, config.key),
            TextArea('fee', { label: 'M&G Fee?' }, config.key),
        ]),
    ];
};
