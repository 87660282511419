import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { VENUES_RECORD_KEY } from '@models/venues.model';
import { Store } from '@ngrx/store';

import { LoadVenues } from '@rootStore/actions/venues.actions';
import { trace } from '@angular/fire/performance';

@Injectable({
    providedIn: 'root',
})
export class VenuesService {
    constructor(private db: AngularFirestore, private store: Store<any>) {}

    updateData(userId) {
        this.db
            .collection(VENUES_RECORD_KEY, (ref) => ref.where('assigned_to', 'array-contains', userId))
            .valueChanges()
            .pipe(trace('get_venues'))
            .subscribe((data) => {
                this.store.dispatch(new LoadVenues(data));
            });
    }
}
