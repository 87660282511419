import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';

import { MaterialModule } from './libs/material.module';

import { ROUTES } from './app.routing';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthGuard } from '@common/guards';
import { SoftwareUpdateDialogModule } from '@common/components/sofware-update-dialog';
import { UserService } from './services';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { HotkeysDialogModule } from '@common/hotkeys/hotkeys-dialog/hotkeys-dialog.module';
import { AdminGuard } from '@common/guards/admin.guard';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { EmailValidator, EmailValidatorMessage } from '@formly/formly-custom-validators';
import {
    AngularFireAnalyticsModule,
    ScreenTrackingService,
    APP_NAME,
    APP_VERSION,
    UserTrackingService,
} from '@angular/fire/analytics';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/performance';
import { USER_SERVICE_TOKEN } from '@tminc/data-access-user';
import { LOGIN_TITLE_TOKEN } from '@tminc/feature-login';

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        LayoutModule,
        MaterialModule,
        RouterModule.forRoot(ROUTES, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireStorageModule,
        AngularFireFunctionsModule,
        AngularFireAnalyticsModule,
        AngularFirePerformanceModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        EffectsModule.forRoot([]),
        // Instrumentation must be imported after importing StoreModule (config is optional)
        !environment.production
            ? StoreDevtoolsModule.instrument({
                  maxAge: 25, // Retains last 25 states
                  logOnly: environment.production, // Restrict extension to log-only mode
              })
            : [],
        MatSnackBarModule,
        ClipboardModule,
        // HotkeysDialogModule,
        ServiceWorkerModule.register('./ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately',
        }),
        SoftwareUpdateDialogModule,
        FormlyMaterialModule,
        FormlyModule.forRoot({
            extras: { lazyRender: true },
            validators: [{ name: 'email', validation: EmailValidator }],
            validationMessages: [
                { name: 'email', message: EmailValidatorMessage },
                { name: 'required', message: 'Required' },
            ],
        }),
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    providers: [
        AuthGuard,
        AdminGuard,
        UserService,
        { provide: USER_SERVICE_TOKEN, useExisting: UserService },
        UserTrackingService,
        ScreenTrackingService,
        PerformanceMonitoringService,
        { provide: APP_VERSION, useValue: '0.8.0' },
        { provide: APP_NAME, useValue: 'All Access' },
        { provide: LOGIN_TITLE_TOKEN, useValue: 'TM • Venue Account' },
    ],
})
export class AppModule {}
