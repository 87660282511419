import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { PROMOTER_ADVANCES_RECORD_KEY } from '@models/promoter-advances.model';
import { Store } from '@ngrx/store';

import { LoadPromoterAdvances } from '@rootStore/actions/promoter-advances.actions';
import { trace } from '@angular/fire/performance';

@Injectable({
    providedIn: 'root',
})
export class PromoterAdvancesService {
    constructor(private db: AngularFirestore, private store: Store<any>) {}

    updateData(userId: string) {
        // updateData(agency_id: string) {
        this.db
            .collection(PROMOTER_ADVANCES_RECORD_KEY, (ref) => ref.where('assigned_to', 'array-contains', userId))
            // .collection(PROMOTER_ADVANCES_RECORD_KEY, (ref) => ref.where('agency_id', '==', agency_id))
            .valueChanges()
            .pipe(trace('get_promoter_advances'))
            .subscribe((data) => {
                this.store.dispatch(new LoadPromoterAdvances(data));
            });
    }
}
