import { Action } from '@ngrx/store';

export enum EventsActionTypes {
  LoadEvents = '[Events] Load Eventss'
}

export class LoadEvents implements Action {
  readonly type = EventsActionTypes.LoadEvents;
  constructor(public payload: any) {}
}

export type EventsActions = LoadEvents;
