export const environment = {
    production: true,
    firebase: {
        apiKey: 'AIzaSyD3MsRqxRAUCwjxR-9DmPROQ6CPxglyZ38',
        authDomain: 'tminc-app-v08.firebaseapp.com',
        databaseURL: 'https://tminc-app-v08.firebaseio.com',
        projectId: 'tminc-app-v08',
        storageBucket: 'tminc-app-v08.appspot.com',
        messagingSenderId: '608159372564',
        appId: '1:608159372564:web:c54214c4366856cb681e01',
        measurementId: 'G-RPVPC5YPXV',
    },
};
