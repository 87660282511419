import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ArtistsService } from './artists.service';
import { ShowProfilesService } from './show-profiles.service';
import { EventsService } from './events.service';
import { ToursService } from './tours.service';
import { Store } from '@ngrx/store';
import { PromoterAdvancesService } from './promoter-advances.service';
import firebase from 'firebase/app';
import 'firebase/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { User, USERS_RECORD_KEY } from '@models/user.model';
import { getObservableSnapshot } from '@utils/observable-helper';
import { Observable, ReplaySubject } from 'rxjs';
import { VenuesService } from './venues.service';
import { UserRoles } from '@tokens/user-roles.token';

@Injectable()
export class UserService {
    userId;
    private user: ReplaySubject<User> = new ReplaySubject(1);
    user$: Observable<User>;
    constructor(
        private auth: AngularFireAuth,
        private router: Router,
        private artistsService: ArtistsService,
        private showProfilesService: ShowProfilesService,
        private eventsService: EventsService,
        private toursService: ToursService,
        private venuesService: VenuesService,
        private promoterAdvancesService: PromoterAdvancesService,
        private store: Store<any>,
        private db: AngularFirestore,
    ) {
        this.auth.authState.pipe(filter((authData) => !!authData)).subscribe((authData: firebase.User) => {
            this.userId = authData.uid;
            this.getUserRecord();
        });
        this.user$ = this.user.asObservable();
    }

    private async getUserRecord() {
        const user: User = await getObservableSnapshot(
            this.db.doc(`${USERS_RECORD_KEY}/${this.userId}`).valueChanges(),
        );
        this.toursService.updateData(user.agency_id);
        this.eventsService.updateData(user.agency_id, user.id);
        this.showProfilesService.updateData(user.agency_id);
        // Update Store Data With Latest User
        this.artistsService.updateData(this.userId);
        this.venuesService.updateData(this.userId);
        this.promoterAdvancesService.updateData(this.userId);
        this.user.next(user);
        return user;
    }

    login(auth) {
        this.auth
            .signInWithEmailAndPassword(auth.email, auth.password)
            .then(async (res) => {
                this.userId = res?.user?.uid;
                const user: User = await this.getUserRecord();
                user.roles.includes(UserRoles.Promoter)
                    ? this.router.navigate(['venues'])
                    : this.router.navigate(['events']);
            })
            .catch((error) => {
                alert(error.message);
            });
    }

    logout() {
        this.auth.signOut().then((res) => {
            // Reset store data
            this.store.dispatch({ type: 'CLEAR_STATE' });
            this.user.next(undefined);
            this.userId = '';
            this.router.navigate(['/']);
        });
    }
}
