import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { SHOW_PROFILES_RECORD_KEY } from '@models/show-profiles.model';
import { Store } from '@ngrx/store';

import { LoadShowProfiles } from '@rootStore/actions/show-profiles.actions';
import { trace } from '@angular/fire/performance';

@Injectable({
    providedIn: 'root',
})
export class ShowProfilesService {
    constructor(private db: AngularFirestore, private store: Store<any>) {}

    updateData(agency_id: string) {
        this.db
            .collection(SHOW_PROFILES_RECORD_KEY, (ref) => ref.where('agency_id', '==', agency_id))
            .valueChanges()
            .pipe(trace('get_show_profiles'))
            .subscribe((data) => {
                this.store.dispatch(new LoadShowProfiles(data));
            });
    }
}
