export const VENUES_RECORD_KEY = 'venues';
export interface VENUE {
    photo?: string;
    name: string;
    city: string;
    state: string;
    place_id: string;
    address: string;
    phone_number: string;
    google_maps_url: string;
    website: string;
    assigned_to?: string[];
}
