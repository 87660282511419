export const EVENTS_RECORD_KEY = 'events';

export interface EVENT {
    agency_id: string;
    archived: boolean;
    artist_name: string;
    artistId: string;
    city: string;
    createdAt: Date;
    date: any;
    name: string;
    notes: string;
    promoterContact: string;
    productionEmail: string;
    productionContact: string;
    promoterEmail: string;
    state: string;
    template: string;
    tourId: string;
    uid: string;
    venue: string;
    venue_id: string;
}
