import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ARTISTS_RECORD_KEY } from '@models/index';
import { Store } from '@ngrx/store';

import { LoadArtists } from '@rootStore/actions/artists.actions';
import { trace } from '@angular/fire/performance';

@Injectable({
    providedIn: 'root',
})
export class ArtistsService {
    constructor(private db: AngularFirestore, private store: Store<any>) {}

    updateData(userId) {
        this.db
            .collection(ARTISTS_RECORD_KEY, (ref) => ref.where('assigned_to', 'array-contains', userId))
            .valueChanges()
            .pipe(trace('get_artists'))
            .subscribe((data) => {
                this.store.dispatch(new LoadArtists(data));
            });
    }
}
