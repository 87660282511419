import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { TOURS_RECORD_KEY } from '@models/index';
import { Store } from '@ngrx/store';

import { LoadTours } from '@rootStore/actions/tours.actions';
import { trace } from '@angular/fire/performance';

@Injectable({
    providedIn: 'root',
})
export class ToursService {
    constructor(private db: AngularFirestore, private store: Store<any>) {}

    updateData(agency_id: string) {
        this.db
            .collection(TOURS_RECORD_KEY, (ref) =>
                ref.where('agency_id', '==', agency_id).where('archived', '!=', true),
            )
            .valueChanges()
            .pipe(trace('get_tours'))
            .subscribe((data) => {
                this.store.dispatch(new LoadTours(data));
            });
    }
}
