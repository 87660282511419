import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { EVENTS_RECORD_KEY } from '@models/index';
import { Store } from '@ngrx/store';

import { LoadEvents } from '@rootStore/actions/events.actions';
import { trace } from '@angular/fire/performance';
import * as dayjs from 'dayjs';

@Injectable({
    providedIn: 'root',
})
export class EventsService {
    constructor(private db: AngularFirestore, private store: Store<any>) {}

    updateData(agency_id: string, user_id: string) {
        const yesterday = dayjs().subtract(1, 'day');
        this.db
            .collection(EVENTS_RECORD_KEY, (ref) =>
                ref
                    .where('agency_id', '==', agency_id)
                    .where('assigned_to', 'array-contains', user_id)
                    .where('date', '>=', yesterday.toDate()),
            )
            .valueChanges()
            .pipe(trace('get_events'))
            .subscribe((data) => {
                this.store.dispatch(new LoadEvents(data));
            });
    }
}
